//
// reboot.scss
//

html {
    position: relative;
    scroll-behavior: smooth;
    @apply h-full w-full;
}

body {
    overflow-x: hidden;
    @apply antialiased font-base font-normal text-sm leading-normal bg-body text-default-500 h-full w-full;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-semibold text-default-800;
}

code {
    @apply text-sky-500;
}

.text-muted {
    @apply text-default-400;
}