//
// buttons.scss
//

@layer components {
    .btn {
        @apply inline-flex justify-center items-center gap-x-2 text-center rounded-lg border border-transparent px-3 py-1.5 text-sm transition-all font-semibold disabled:opacity-50 disabled:pointer-events-none;

        &.btn-lg {
            @apply px-5 py-3 text-base;
        }

        &.btn-sm {
            @apply px-3.5 py-1.5 text-xs;
        }
    }
}