/*
Template Name: AdminHub - Tailwind CSS 3 Admin Layout & UI Kit Template
Version: 1.0
Author: MyraStudio
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  position: relative;
  scroll-behavior: smooth;
  @apply h-full w-full;
}

body {
  overflow-x: hidden;
  @apply antialiased font-base font-normal text-sm leading-normal bg-body text-default-500 h-full w-full;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold text-default-800;
}

code {
  @apply text-sky-500;
}

.text-muted {
  @apply text-default-400;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}
.custom-scroll::-webkit-scrollbar:vertical {
  width: 4px;
}
.custom-scroll::-webkit-scrollbar:horizontal {
  height: 4px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-slate-300/50;
}
.custom-scroll::-webkit-scrollbar-track {
  @apply bg-transparent rounded-lg;
}
.custom-scroll::-webkit-scrollbar-corner {
  @apply bg-transparent;
}

@layer components {
  .btn {
    @apply inline-flex justify-center items-center gap-x-2 text-center rounded-lg border border-transparent px-3 py-1.5 text-sm transition-all font-semibold disabled:opacity-50 disabled:pointer-events-none;
  }
  .btn.btn-lg {
    @apply px-5 py-3 text-base;
  }
  .btn.btn-sm {
    @apply px-3.5 py-1.5 text-xs;
  }
}
@layer components {
  .card {
    @apply relative rounded-md bg-white border border-default-200 flex flex-col break-words bg-clip-border h-fit;
  }
  .card .card-header {
    @apply py-3 px-6 border-b border-gray-200;
  }
  .card .card-header.card-tabs {
    @apply pb-0;
  }
  .card .card-footer {
    @apply py-3 px-6 border-t border-gray-200;
  }
  .card .card-body {
    @apply p-5;
  }
  .card-title {
    @apply text-base font-semibold text-default-800;
  }
}
@layer components {
  label {
    @apply inline-block text-sm font-semibold cursor-pointer text-default-600;
  }
  .form-input,
  .form-textarea,
  .form-select {
    @apply font-normal rounded block w-full border-gray-200 text-sm focus:border-gray-300 focus:ring-0 bg-white;
  }
  .form-checkbox,
  .form-radio {
    @apply bg-inherit cursor-pointer border-gray-300 focus:ring-0 focus:ring-offset-0;
  }
  .form-switch {
    @apply w-9 h-5 flex items-center appearance-none bg-gray-200 border-2 border-transparent rounded-full focus:ring-0 focus:ring-offset-0 cursor-pointer transition-colors ease-in-out duration-200 checked:bg-none;
  }
  .form-switch::before {
    @apply content-[""] inline-block w-4 h-4 bg-white rounded-full translate-x-0 transform transition ease-in-out checked:translate-x-full duration-200;
  }
  .form-switch.form-switch-lg {
    @apply w-11 h-6;
  }
  .form-switch.form-switch-lg::before {
    @apply w-5 h-5;
  }
  .card-radio {
    @apply relative;
  }
  .card-radio .form-label {
    @apply border overflow-hidden text-ellipsis whitespace-nowrap flex flex-col cursor-pointer border-gray-200;
  }
  .card-radio .form-radio {
    @apply hidden;
  }
  .card-radio .form-radio:checked + .form-label {
    @apply border-primary text-primary;
  }
  .btn-radio .form-radio {
    @apply hidden;
  }
  .btn-radio .form-radio:checked + label {
    @apply bg-primary text-white;
  }
}
.apex-charts {
  min-height: 10px !important;
}

.apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-canvas .apexcharts-text.apexcharts-xaxis-label,
.apexcharts-canvas .apexcharts-text.apexcharts-yaxis-label,
.apexcharts-canvas .apexcharts-title-text {
  @apply fill-default-900 bg-default-50;
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light,
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light {
  @apply shadow-none rounded-lg border border-default-300 bg-default-50/10 backdrop-blur-[100px];
}

.apexcharts-canvas .apexcharts-xaxistooltip-bottom:after,
.apexcharts-canvas .apexcharts-xaxistooltip-bottom:before {
  @apply border-b-default-200 border-dashed;
}

.apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active {
  @apply text-default-50 px-4 pb-2;
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  @apply px-4 py-2 mb-2 text-sm text-center border-default-400 border-dashed bg-default-200/10 backdrop-blur-[100px];
}

.apexcharts-grid-borders line,
.apexcharts-gridline {
  @apply stroke-default-200;
}

.apexcharts-legend-series {
  @apply font-semibold;
}

.apexcharts-legend-text {
  @apply text-default-300;
  font-size: 13px !important;
  vertical-align: middle;
}

.apexcharts-legend-marker {
  vertical-align: middle;
  margin-right: 5px !important;
}

.apexcharts-pie-label {
  @apply fill-white;
}

.apexcharts-text.apexcharts-yaxis-title-text {
  @apply fill-default-600;
}

.apexcharts-xaxis line,
.apexcharts-xaxis-tick {
  @apply stroke-default-400;
}

.apex-charts text,
.apexcharts-tooltip-title,
.apexcharts-tooltip-text,
.apexcharts-xaxistooltip-text,
.apexcharts-yaxis .text,
.apexcharts-xaxis .text,
.apexcharts-datalabels text,
.apexcharts-title-text,
.apexcharts-legend-text,
.apexcharts-subtitle-text,
.apexcharts-tooltip {
  font-family: Montserrat, sans-serif !important;
  @apply text-default-900 font-base font-medium;
}

.wrapper {
  @apply flex flex-col min-h-full;
}

.page-content {
  @apply flex flex-col h-screen flex-auto relative transition-all duration-300 lg:p-layout-gap overflow-hidden;
}

main {
  @apply flex-grow overflow-y-auto h-full w-full bg-white border border-default-200 my-16 lg:my-0 lg:rounded-xl;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.page-content {
  @apply lg:ms-sidenav;
}

@screen lg {
  #app-menu {
    @apply lg:py-layout-gap;
  }
  #app-menu.opened {
    @apply -ms-sidenav;
  }
  #app-menu.opened + .page-content {
    @apply ms-layout-gap;
  }
}
.admin-menu a.active {
  @apply text-primary bg-primary/10;
}
.admin-menu a.active .material-symbols-rounded {
  @apply fill-1;
}

.app-header {
  @apply fixed top-0 w-full z-50;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  inset-inline-start: 0;
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  inset-inline-start: 0 !important;
  bottom: 0;
  inset-inline-end: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: inset-inline-start;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  inset-inline-end: 2px;
  width: 4px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  content: "";
  position: absolute;
  border-radius: 7px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
  @apply bg-slate-300;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  inset-inline-start: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  inset-inline-start: 2px;
  inset-inline-end: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  inset-inline-end: auto;
  inset-inline-start: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  inset-inline-end: auto;
  inset-inline-start: 0;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  inset-inline-start: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

[data-simplebar-lg] .simplebar-scrollbar {
  inset-inline-end: 1px;
  width: 10px;
}

[data-simplebar-primary] .simplebar-scrollbar:before {
  @apply bg-primary;
}